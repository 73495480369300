import React from "react";
import { graphql } from "gatsby";
import CleanLayout from "../layouts/Clean";
import { Intro, SEO, PostPreview } from "components";
import { getImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";

const BlogCategory = ({ data }) => {
  //   const posts = data?.allContentfulBlogPost?.nodes;
  const {
    allContentfulBlogPost: { nodes: posts },
    allContentfulCategory: {
      nodes: [category],
    },
  } = data;
  if (!posts || !category) return null;
  return (
    <CleanLayout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title={`${category.title} | ${category.name}`}
      />
      <div className="w-screen px-8 md:px-28">
        <Intro
          title={category.title}
          description={renderRichText(category.description)}
        />
        <section className="flex flex-col">
          {posts.map((post, index) => {
            const pImage = getImage(post?.image);
            return (
              <div className="my-8" key={index}>
                <PostPreview {...post} image={pImage} />
              </div>
            );
          })}
        </section>
      </div>
    </CleanLayout>
  );
};

export default BlogCategory;

export const categoryQuery = graphql`
  query getCategory($skip: Int!, $limit: Int!, $category: String!) {
    allContentfulBlogPost(
      filter: {
        node_locale: { eq: "it" }
        category: { slug: { eq: $category } }
      }
      sort: { fields: [createdAt], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        slug
        category {
          name
          slug
        }
        title
        image {
          gatsbyImageData
        }
        author {
          name
          tagline
        }
        subtitle {
          subtitle
        }
      }
    }
    allContentfulCategory(filter: { slug: { eq: $category } }) {
      nodes {
        title
        name
        description {
          raw
        }
      }
    }
  }
`;
